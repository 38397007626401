import { useState } from 'react'; 
import * as constDefs from '../constants/constants';
import classes from './PlayerAdd.module.css';

function PlayerEdit(props) {
    const [activePlayer, setActivePlayer] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedRating, setSelectedRating] = useState('');
    const [phoneNo, setPhoneNo] = useState('');

    const handleChangeName = (event) => {
        setPlayerName(event.target.value);
    }

    const handleChangeGender = (event) => {
        setSelectedGender(event.target.value);
    }

    const handleChangeRating = (event) => {
        setSelectedRating(event.target.value);
    }

    const handleChangePhoneNo = (event) => {
        setPhoneNo(event.target.value);
    }


    if (props.activePlayer && props.activePlayer !== activePlayer) {
        let player = props.getPlayerById(props.activePlayer);
        setPlayerName(player[0].name);
        setSelectedGender(player[0].gender);
        setSelectedRating(player[0].rating);
        if (player[0].phone_number) {
            setPhoneNo(player[0].phone_number);
        } else {
            setPhoneNo('');
        }
       setActivePlayer(props.activePlayer);
    }

    function handleUpdate(event) {
        event.preventDefault();
        console.log(activePlayer);
        console.log(playerName);
        console.log(selectedGender);
        console.log(selectedRating);
        console.log(phoneNo);
        props.onUpdatePlayer(activePlayer, playerName, selectedGender, selectedRating, phoneNo);
    }

    return (
        <div className={classes.playerAddEdit}  >
            <form className={classes.playerAddEditForm}  >
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="playername">Name:</label>
                    <input className={classes.inputBoxName} type="text" id="playername" name="playername" value={playerName} onChange={handleChangeName} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabelGender} htmlFor="gender">Gender:</label>
                    <select className={classes.inputBox} id="gender" name="gender" value={selectedGender} onChange={handleChangeGender}>
                        <option value={constDefs.female}>{constDefs.female}</option>
                        <option value={constDefs.male}>{constDefs.male}</option>
                    </select>
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabelRating} htmlFor="rating">Rating:</label>
                    <select className={classes.inputBox} id="rating" name="rating" value={selectedRating} onChange={handleChangeRating}>
                        <option value={constDefs.twoFive}>{constDefs.twoFive}</option>
                        <option value={constDefs.threeZero}>{constDefs.threeZero}</option>
                        <option value={constDefs.threeFive}>{constDefs.threeFive}</option>
                    </select>
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="phoneno">PhoneNo:</label>
                    <input className={classes.inputBox} type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="1112223333"  id="phoneno" name="phoneno" value={phoneNo} onChange={handleChangePhoneNo} />
                </div>
                <div> (Ex: 1112223333)</div>

                <button className={classes.playerAddButton} type="submit" onClick={handleUpdate}>Update</button>
            </form>
        </div>
    ); 
}

export default PlayerEdit;
//        <div className={classes.playerAdd} id={props.playerInfo.id}  ></div>