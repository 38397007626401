import PlayerCategories from './PlayerCategories';
import {updatePlayers } from '../dataAccess/getData';
import classes from './PlayerAvailabilityUpdate.module.css';

//Component
function PlayerAvailabilityUpdate(props) {

    function getPlayerAvailability(players) {
        return players.filter((player) => player.id === props.currentPlayer.id);
    };

    let currentPlayer = getPlayerAvailability(props.loadedPlayersAvailability);

    function updateAvailabilityHandler(e) {
        let playerId = e.target.getAttribute("data-playerid");
        let matchId = e.target.getAttribute("data-matchid");
        let availability = e.target.getAttribute("data-available");

        const updatedPlayers = props.loadedPlayersAvailability.map(player => {
            if (player.id === playerId) {
                let updatedMatches = player.matches.map(match => {
                    if (match.matchId === matchId) {
                        return {
                            ...match,
                            availability: match.availability = availability
                        };
                    }
                    else {
                        return match;
                    }
                })
                return {
                    ...player,
                    matches: updatedMatches
                }
            } else {
               return player;
            }
        });

        // POST 
        try {
            let returnVal = updatePlayers(updatedPlayers, props.onError);
            let updatedPlayer = getPlayerAvailability(updatedPlayers);
            // Update state
            props.onUpdatePlayerHandler(updatedPlayer);
        }
        catch(error) {
            props.onError(error);
        }
     }

    // Begin content setup
    let content = <p>No player found.</p>;

    // Set up content for rendering
    if (currentPlayer) {
        content = (
            <div className={classes.levelPlayerSingleBlock}> 
                    <div key={currentPlayer[0].id}>
                        <PlayerCategories readOnly={props.readOnly} playerInfo={currentPlayer[0]} matchList={props.matchList} onUpdateAvailability={updateAvailabilityHandler}  />
                    </div>
            </div>
        );
    }

    return (
        <div className={classes.playersLayout}> 
            {content}
        </div>
    ); 
}

export default PlayerAvailabilityUpdate;
