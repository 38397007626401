import * as constDefs from '../constants/constants';
import classes from './PlayerList.module.css';

function PlayerList(props) {

    let playerListRowClass = classes.playerListRow;
    let playerNameClass = classes.playerName;
    let draggableFlag = false;
    let numberPast = 0;
    let numberFuture = 0;

    props.playerInfo.matches.forEach(function(match) {
        if (((props.activeMatch) && (props.activeMatch.length > 0) && (match.matchId === props.activeMatch[0].id)) && (match.availability === constDefs.availYes )) {
            playerListRowClass = `${classes.playerListRow} ${classes.playerActive}`; 
            playerNameClass = `${classes.playerName} ${classes.playerNameActive}`;
            draggableFlag = true;
        }
    })

    if (props.playersMatchesPast) {
        numberPast =  props.playersMatchesPast.find((p) => { 
            return p.id === props.playerInfo.id;
        });
    }
 
    if (props.playersMatchesFuture) {
        numberFuture =  props.playersMatchesFuture.find((p) => { 
            return p.id === props.playerInfo.id;
        });
    }
 
    return (
        <div className={`${playerListRowClass}`} id={props.playerInfo.id}  >
            <div className={`${playerNameClass}`} draggable={draggableFlag} onDragStart={props.drag} id={props.playerInfo.id}>
                {props.playerInfo.name}({numberPast.numOfMatches}){numberFuture.numOfMatches}*
            </div>
        </div>
    ); 
}

export default PlayerList;