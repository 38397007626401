import classes from './MatchInfoAvailability.module.css';
import * as constDefs from '../constants/constants';

function MatchInfoAvailability(props) {

    let foundMatch = props.player.matches.filter(match => match.matchId === props.matchInfo.id);

    let buttonYesClass = classes.yesButton;
    let buttonMysteryClass = classes.mysteryButton;
    let buttonNoClass = classes.noButton;

    let buttonYesClassRO = classes.yesButtonRO;
    let buttonNoClassRO = classes.noButtonRO;

    let foundMatchAvail = foundMatch[0].availability ? foundMatch[0].availability : "mystery";

    switch ((foundMatchAvail).toLowerCase()) {
        case constDefs.availMystery:
            buttonMysteryClass = classes.mysteryButtonSet;
            break;
        case constDefs.availYes:
            buttonYesClass = classes.yesButtonSet;
            buttonYesClassRO = classes.yesButtonSetRO;
            break;
        case constDefs.availNo:
            buttonNoClass = classes.noButtonSet;
            buttonNoClassRO = classes.noButtonSetRO;
            break;
        default:
            break;
    }

    let content = "";

    if (props.readOnly) {
        content = (
            <div className={classes.availability}>
                <div>
                    <span type="button" className={`${classes.availabilityButtonRO} ${buttonNoClassRO}`} 
                        data-playerid={props.player.id} 
                        data-matchid={props.matchInfo.id}
                        data-available={constDefs.availNo}
                        >NO</span>
                    <span type="button" className={`${classes.availabilityButtonRO} ${buttonYesClassRO}`}
                        data-playerid={props.player.id} 
                        data-matchid={props.matchInfo.id}
                        data-available={constDefs.availYes}
                        >YES</span>
                </div>
            </div>
        )
    } else {
        content = (
            <div className={classes.availability}>
                <div>
                    <span type="button" className={`${classes.availabilityButton} ${buttonNoClass}`} 
                        data-playerid={props.player.id} 
                        data-matchid={props.matchInfo.id}
                        data-available={constDefs.availNo}
                        onClick={props.onUpdateAvailability}>NO</span>
                    <span type="button" className={`${classes.availabilityButton} ${buttonYesClass}`}
                        data-playerid={props.player.id} 
                        data-matchid={props.matchInfo.id}
                        data-available={constDefs.availYes}
                        onClick={props.onUpdateAvailability}>YES</span>
                </div>
            </div>
        )
    }

    return (
        <div>
            {content}
        </div>
    ); 
}

export default MatchInfoAvailability;
