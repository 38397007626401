import React, { useState, useEffect } from 'react'; 
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//import { BrowserRouter, Routes, Route } from 'react-router-dom';

//import * as routeLinks from './routes/routes';
import * as constDefs from './constants/constants';
import Layout from './components/shared/Layout';
import Root from './pages/Root';
import AvailabilityUpdate, {loader as updateMatchesLoader} from  './pages/AvailabilityUpdate';
import Availability, {loader as matchesLoader} from  './pages/Availability';
import Lineup from './pages/Lineup'; 
import LineupAdmin from './pages/LineupAdmin'; 
import AdminAvailability, {loader as adminMatchesLoader} from  './pages/AdminAvailability';
import AdminPlayers from './pages/AdminPlayers';
import AdminMatches from './pages/AdminMatches';
import Login from './pages/Login';
import AvailableError from './components/shared/AvailableError';
import useToken from './useToken';
import { verifyToken } from './dataAccess/getData';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <AvailableError />,
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          { path: '/availabilityupdate', element: <AvailabilityUpdate />, errorElement: <AvailableError />, loader: updateMatchesLoader},
          { path: '/availability', element: <Availability />, errorElement: <AvailableError />, loader: matchesLoader},
          { path: '/lineup', element: <Lineup />},
          { path: '/lineupadmin', element: <LineupAdmin />},
          { path: '/adminAvailability', element: <AdminAvailability />, errorElement: <AvailableError />, loader: adminMatchesLoader},
          { path: '/adminPlayers', element: <AdminPlayers />},
          { path: '/adminMatches', element: <AdminMatches />},
        ]
      },
      //{ index: true, element: <MainHeader />},
    ],
  },
]);

function App() {
     const { token, setToken } = useToken(); 
     const [isLoading, setIsLoading] = useState(true);
     //const [userName, setUserName] = useState('');

     //console.log("In App, userName is: " + sessionStorage.getItem(constDefs.authNameKey));

  // useEffect to verify Token 
  useEffect(() => {
    setIsLoading(true);
    let tokenObj = {"token": token};
    //console.log("In App, in useEffect.");
    verifyToken(tokenObj)
        .then((returnVal) => {
                if (returnVal.tokenExists === 'yes') {
                  if (returnVal.id) {
                      sessionStorage.setItem(constDefs.authIdKey, returnVal.id);
                      sessionStorage.setItem(constDefs.authNameKey, returnVal.name);
                      //console.log("In App, setting sessionStorage.");
                      //setUserName(returnVal.name);
                      setIsLoading(false);
                  } else {
                      sessionStorage.removeItem(constDefs.authIdKey);
                      sessionStorage.removeItem(constDefs.authNameKey);
                      return <Login setToken={setToken} />
                  }
                } else {
                  sessionStorage.removeItem(constDefs.authIdKey);
                  sessionStorage.removeItem(constDefs.authNameKey);
                  return <Login setToken={setToken} />
                }
            })
        .catch(e => {
            let errMsg = "ERROR: verifyToken: " + e;
        });
  }, [token]);

  if (!token) {
    return <Login setToken={setToken} />
  }

  let content = "";

  if (isLoading) {
      content = (<p>Loading TennisTime...</p>);
      //console.log("In App.js loading.");
  } else {
    content = (<RouterProvider router={router} /> );
    //console.log("In App.js loading finished.");
  }

//  content = (<RouterProvider router={router} /> );

  return (
      <div>
        {content}
      </div>
  );

}

export default App;


/*
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={routeLinks.availabilityRoute} element={<Availability />} />
          <Route path={routeLinks.lineupRoute} element={<Lineup />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
*/