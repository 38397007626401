import * as constDefs from '../constants/constants';
import MatchInfo from './MatchInfo';
import classes from './MatchListAdmin.module.css';

function MatchListAdmin(props) {

    let matchListRowClass = classes.matchListRow;
    let matchCardClass = classes.matchCard;
    //let matchClass = classes.matchButton;
    let matchInfoStuffClass = classes.matchInfoStuffButton;

    if (props.activeMatch && props.activeMatch === props.matchInfo.id) {
        matchCardClass = `${classes.matchCard} ${classes.matchActive}`;
        //matchListRowClass = `${classes.matchListRow} ${classes.matchActive}`; 
        //matchClass = `${classes.matchButton} ${classes.matchNameActive}`;
        matchInfoStuffClass = `${classes.matchInfoStuffButton} ${classes.matchInfoStuffButtonActive}`;
    }

    /*
    function onSetMatchActive(e) {
        e.preventDefault();
        props.
    }
        */
 
    return (
                <div className={classes.displayMatchCardTop}>
                    <div className={classes.buttonMatchInfo}>

                        <div data-matchid={props.matchInfo.id} id={props.matchInfo.id} onClick={props.onSetMatchActive}> 
                            <button className={`${matchInfoStuffClass}`} key={props.matchInfo.id} id={props.matchInfo.id} data-matchid={props.matchInfo.id} onClick={props.onSetMatchActive}>
                                        <div className={classes.title} data-matchid={props.matchInfo.id}>
                                            {props.matchInfo.date}
                                        </div>
                                        <div data-matchid={props.matchInfo.id}>
                                            {props.matchInfo.dayOfWeek}
                                        </div>
                                        <div data-matchid={props.matchInfo.id}>
                                            {props.matchInfo.time}
                                        </div>
                                        <div data-matchid={props.matchInfo.id}>
                                            {props.matchInfo.location}
                                        </div>
                                        <div data-matchid={props.matchInfo.id}>
                                            {props.matchInfo.opponent}
                                        </div>
                            </button>
                        </div>
                    </div>
                </div>
    ); 
}

export default MatchListAdmin;
//        <div className={`${matchListRowClass}`} id={props.matchInfo.id}  ></div>
//                        <div className={`${matchClass}`} id={props.matchInfo.id} onClick={props.onSetMatchActive}>
//            <div className={`${matchCardClass}`}> 