import classes from './CourtAssign.module.css';

function CourtAssign(props) {

    let courtNumber = props.court[0][0].substring(0,6);
    courtNumber = courtNumber.charAt().toUpperCase() + courtNumber.slice(1);

    // changedPlayer is a string consisting of "matchId,playerId"
    // i.e "12,5"
    let changedPlayer = props.changedPlayer;

    function setOnDrop(event) {
        event.preventDefault();

        var evArray = event.target.id.split(",");
        var courtPos = evArray[0];

        var plArray = changedPlayer.split(",");
        var playerId = plArray[1];

        event.target.id = changedPlayer + "," + courtPos;
        props.onSetOnDrop(event);
        props.onSetPlayerNOMChange(props.matchInfo, playerId, 'add');
    }

    function getPlayerName(player) {
        let name = props.getPlayerNameById(player[1]);
        return name;
    }

    let courtsContent = "";

    if (props.readOnly) {
        courtsContent = (
            <div className={classes.courtAssignLayout}>
                {props.court.map((courtPlayer) => (
                    <div key={courtPlayer} className={classes.courtPlayerPlacement}>
                        <div id={courtPlayer} data-matchid={props.matchInfo.id} changedplayer-id={changedPlayer} className={classes.playerName} >
                           {getPlayerName(courtPlayer)}
                        </div>
                    </div>
                ))}
            </div>
        )
    } else {
        courtsContent = (
            <div className={classes.courtAssignLayout}>
                {props.court.map((courtPlayer) => (
                    <div key={courtPlayer} className={classes.courtPlayerPlacement}>
                        <div id={courtPlayer} data-matchid={props.matchInfo.id} changedplayer-id={changedPlayer} className={classes.playerName} onDragOver={props.onSetAllowDrop} onDrop={event => setOnDrop(event)}>
                           {getPlayerName(courtPlayer)}
                        </div>
                        <div>
                            <button id={courtPlayer} className={classes.playerRemoveButton} data-matchid={props.matchInfo.id} onClick={props.onSetRemovePlayer}>X</button>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div> 
            {courtsContent}
        </div>
    ); 
}

export default CourtAssign;
