import { useState, useEffect } from 'react';
import { getPlayers, updatePlayers } from '../dataAccess/getData.js';
import { getPlayersSorted } from '../shared.js'

import PlayerListAdmin from '../components/PlayerListAdmin.js';
import PlayerAdd from '../components/PlayerAdd.js';
import PlayerEdit from '../components/PlayerEdit.js';
//import Pass from '../components/Pass.js'; 
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling.js'; 
import * as constDefs from '../constants/constants.js';
import classes from './AdminPlayers.module.css';

//Component
function AdminPlayers(props) {
    const [error, setError] = useState('');
    const [currentPlayers, setCurrentPlayers] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [activePlayer, setActivePlayer] = useState(null);
    //const [readOnly, setReadOnly] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    // Error Handling
    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    // Get Permission Level
    /*
    function submitPassKeyHandler(keyPassVal) {
        let keyPassObj = {"readwrite": keyPassVal};
        getPermissionLevel(keyPassObj)
            .then((returnVal) => {
                if (returnVal.readwrite === constDefs.keyValuePass) {
                    setReadOnly(false) 
                } else {
                    setReadOnly(true)
                }
            }).catch(e => {
                let errMsg = "ERROR: getPermissionLevel: " + e;
                updateErrorHandler(errMsg);
            });
    }
    */

    function getPlayerById(id) {
        let foundPlayer = currentPlayers.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return "";
        }
    }

    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    function setPlayerActiveHandler(event) {
        setActivePlayer(event.target.id);
    }

    function addPlayerHandler(playerName, selectedGender, selectedRating, phoneNo) {
        let chPlayerArr = [{id: "", rating: selectedRating, name: playerName, matches: [], gender: selectedGender, phone_number: phoneNo}];

        // POST 
        try {
            //let returnVal = updatePlayers(chPlayerArr, props.onError);
            updatePlayers(chPlayerArr, props.onError)
                .then(data => {
                    let result = data;
                    console.log(data);
                    //if (response === 200) {
                        // Update state
                        setActivePlayer("");
                        setIsLoading(true);
                        getPlayers()
                            .then((players) => {
                                setCurrentPlayers(players) 
                            }).catch(error => {
                                let errMsg = "ERROR: getPlayers: " + error;
                                updateErrorHandler(errMsg);
                            });
                        setIsLoading(false);
                    //}
                    //else {
                    //    let errMsg = "ERROR: updatePlayers: " + error;
                    //    updateErrorHandler(errMsg);
                    //}
                }).catch(error => {
                    let errMsg = "ERROR: catching updatePlayers: " + error;
                    updateErrorHandler(errMsg);
                });
        }
        catch(error) {
            let errMsg = "ERROR: catching on try updatePlayers: " + error;
            updateErrorHandler(errMsg);
            //props.onError(error);
        }
    }

    function updatePlayerHandler(playerId, selectedPlayerName, selectedGender, selectedRating, selectedPhoneNo) {
        let chgPlayer = getPlayerById(playerId);
        if (chgPlayer && chgPlayer.length > 0 ) {
                chgPlayer[0].name = selectedPlayerName;
                chgPlayer[0].gender = selectedGender;
                chgPlayer[0].rating = selectedRating;
                chgPlayer[0].phone_number = selectedPhoneNo;
                // POST 
                try {
                    updatePlayers(chgPlayer, props.onError)
                        .then(data => {
                            let result = data;
                            console.log(data);
                            //if (response === 200) {
                                // Update state
                                setActivePlayer("");
                                setIsLoading(true);
                                getPlayers()
                                    .then((players) => {
                                        setCurrentPlayers(players) 
                                    }).catch(error => {
                                        let errMsg = "ERROR: getPlayers: " + error;
                                        updateErrorHandler(errMsg);
                                    });
                                setIsLoading(false);
                            //}
                            //else {
                            //    let errMsg = "ERROR: updatePlayers: " + error;
                            //    updateErrorHandler(errMsg);
                            //}
                        }).catch(error => {
                            let errMsg = "ERROR: catching updatePlayers: " + error;
                            updateErrorHandler(errMsg);
                        });
                }
                catch(error) {
                    let errMsg = "ERROR: catching on try updatePlayers: " + error;
                    updateErrorHandler(errMsg);
                }         
        }
    }

    function handleClearFields() {
        setActivePlayer('');
    }


    // useEffect to retrieve Players 
    useEffect(() => {
            setIsLoading(true);
            getPlayers()
                .then((players) => {
                    setCurrentPlayers(players) 
                    let userId = sessionStorage.getItem(constDefs.authIdKey);
                    let foundPlayer = getPlayerByIdSecurity(userId, players);
                    if (foundPlayer.length > 0 && foundPlayer[0].hasOwnProperty('captain')) {
                        setReadOnly(false);
                        //setReadOnly(true);
                    } 
                }).catch(error => {
                    let errMsg = "ERROR: getPlayers: " + error;
                    updateErrorHandler(errMsg);
                });
            setIsLoading(false);
    }, []);

    // Start building page content
    let sortedPlayers = [];

    if (currentPlayers) {
       sortedPlayers = getPlayersSorted(currentPlayers);
    }

    let playerListContent = "";
    let addEditPlayerContent = "";
    let addEditPlayerLabel = "";
    let clearFieldsButton = "";

    if (readOnly) {
    } else {
        playerListContent = (
            <div className={classes.playerColumns}>
                {sortedPlayers.map((player) => (
                    <div key={player.id}>
                        <PlayerListAdmin playerInfo={player} activePlayer={activePlayer} onSetPlayerActive={setPlayerActiveHandler}  />
                    </div>
                ))}
            </div>
        )

        if (activePlayer) {
            addEditPlayerLabel = 'Edit Player';
            addEditPlayerContent = (
                <div className={classes.addEditPlayerInput} >
                    <PlayerEdit activePlayer={activePlayer} getPlayerById={getPlayerById} onUpdatePlayer={updatePlayerHandler } />
                </div>
            )
            clearFieldsButton = (
                <button className={classes.clearFieldsButton} onClick={handleClearFields}>
                    Clear Fields 
                </button>
            )
        } else {
            addEditPlayerLabel = 'Add Player';
            addEditPlayerContent = (
                <div className={classes.addEditPlayerInput} >
                    <PlayerAdd onAddPlayer={addPlayerHandler } />
                </div>
            )
        }
    }

    let errorContent = "";
    if (error) {
        errorContent = (
            <div>
               <ErrorMsgHandling errorMessage={error} />
            </div>
        )
    }


    // Begin content setup
    let content = <p>No players found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up PlayersListContent
    if (readOnly) {
    } else {
        if (currentPlayers) {
            content = (
                <div>
                    <div className={classes.componentSection}>
                        <div className={classes.playersLabel}>
                            Players
                        </div>
                        {playerListContent}

                        <div className={classes.addEditPlayerSection}>
                            <div className={classes.addEditLabelSection}>
                                <div className={classes.addEditPlayersLabel}>
                                    {addEditPlayerLabel}
                                </div>
                                {clearFieldsButton}
                            </div>
                            {addEditPlayerContent}
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={classes.adminPlayersLayout}>
            {errorContent}
            {content}
        </div>
    ); 
}

export default AdminPlayers;