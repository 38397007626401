//import MatchInfo from '../components/MatchInfo';
import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { getMatches, getPlayers, getPlayersAvailability } from '../dataAccess/getData';
//import {getPermissionLevel } from '../dataAccess/getData';

import Matches from '../components/Matches';
import PlayerAvailability from '../components/PlayerAvailability';
import PlayersAvailability from '../components/PlayersAvailability';
import * as constDefs from '../constants/constants';
import classes from './Availability.module.css';
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling'; 

function Availability() {
    const [error, setError] = useState('');
    const [loadedPlayersAvailability, setLoadedPlayersAvailability] = useState();
    const [currentPlayer, setCurrentPlayer] = useState();
    const [readOnly, setReadOnly] = useState(true);
    const loadedMatches = useLoaderData();
    const [isLoading, setIsLoading] = useState(false);

    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    function updatePlayerHandler(player) {
        setCurrentPlayer(player);
    }

    function updatePlayersHandler(players) {
        setLoadedPlayersAvailability(players); 
    }

    // Get Permission Level
    /*
    function submitPassKeyHandler(keyPassVal) {
        let keyPassObj = {"readwrite" : keyPassVal};
        getPermissionLevel(keyPassObj)
            .then((returnVal) => {
                if (returnVal.readwrite === constDefs.keyValuePass) {
                    setReadOnly(false) 
                } else {
                    setReadOnly(true)
                }
            }).catch(error => {
                let errMsg = "ERROR: getPermissionLevel: " + error;
                setError(errMsg);
            });
    }
    */

    useEffect(() => {
        setIsLoading(true);
        getPlayersAvailability()
            .then((playersAvailability) => {
                setLoadedPlayersAvailability(playersAvailability);  
            }).catch(error => {
                let errMsg = "ERROR: getPlayersAvailability: " + error;
                console.log(errMsg);
                updateErrorHandler(errMsg);
            });

        setIsLoading(false);
    }, []);

    // useEffect to retrieve Players
    useEffect(() => {
        getPlayers()
            .then((players) => {
                let userId = sessionStorage.getItem(constDefs.authIdKey);
                let foundPlayer = getPlayerByIdSecurity(userId, players);
                if (foundPlayer.length > 0) {
                    setCurrentPlayer(foundPlayer);
                }
                /*
                if (foundPlayer.length > 0 && foundPlayer[0].hasOwnProperty('captain')) {
                    setReadOnly(false);
                    //setReadOnly(true);
                } 
                */
            }).catch(error => {
                let errMsg = "ERROR: getPlayers: " + error;
                console.log(errMsg);
                updateErrorHandler(errMsg);
            });
    }, []);

    if (!Array.isArray(loadedMatches)) {
        if (loadedMatches && loadedMatches.startsWith("ERROR") ) {
            //Note: The following updateErrorHandler call produces an infinite loop. Don't use it like this.
            //      Instead, use the errorElement property provided with React Router.
            //updateErrorHandler(loadedMatches);
            throw new Error("Error from Availability component - " + loadedMatches);
        }
    }

    let content = <p>Welcome to Tennis Time.</p>;
    //const currentPlayerPerm = false;

    if (isLoading) {
        content = <p>Welcome to Tennis Time.</p>;
    }

    if (loadedPlayersAvailability) {
        if (currentPlayer) {
            content = (
                <div>
                    <div className={classes.availabilityLayout}>
                        <ErrorMsgHandling errorMessage={error} />
                        <Matches matchList={loadedMatches} />
                        <PlayerAvailability 
                            onError={updateErrorHandler} 
                            readOnly={readOnly} 
                            matchList={loadedMatches} 
                            loadedPlayersAvailability={loadedPlayersAvailability}
                            currentPlayer={currentPlayer[0]} 
                            onUpdatePlayerHandler={updatePlayerHandler} />
                    </div>
                    <div className={classes.availabilityLayout}>
                        <ErrorMsgHandling errorMessage={error} />
                        <Matches matchList={loadedMatches} />
                        <PlayersAvailability 
                            onError={updateErrorHandler} 
                            readOnly={readOnly} 
                            matchList={loadedMatches} 
                            loadedPlayersAvailability={loadedPlayersAvailability}
                            currentPlayer={currentPlayer[0]} 
                            onUpdatePlayerHandler={updatePlayersHandler} />
                    </div>
                </div>
                )
        } else {
            content = (
                <div>
                    <div className={classes.availabilityLayout}>
                        <ErrorMsgHandling errorMessage={error} />
                        <Matches matchList={loadedMatches} />
                        <PlayersAvailability 
                            onError={updateErrorHandler} 
                            readOnly={readOnly} 
                            matchList={loadedMatches} 
                            loadedPlayersAvailability={loadedPlayersAvailability}
                            onUpdatePlayerHandler={updatePlayersHandler}  />
                    </div>
                </div>
                )
        }
    }

    return (
        <div>
            {content}
        </div>
    ); 
}

export default Availability;

export function loader() {
    const response = getMatches()
        .then((matches) => {
            return matches;
        })
        .catch(error => {
            let errMsg = "ERROR: getMatches: " + error;
            console.log(errMsg);
            return errMsg; 
        });
    return response;
}