import { useState, useEffect } from 'react';
import {getPlayers, getMatches, postToGroupMe } from '../dataAccess/getData.js';

import PlayerList from '../components/PlayerList.js';
import LineupMatches from '../components/LineupMatches.js';
import ModalGroupMe from '../components/ModalGroupMe.js'; 
//import Pass from '../components/Pass.js'; 
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling'; 
import * as constDefs from '../constants/constants.js';
import classes from './Lineup.module.css';

//Component
function Lineup(props) {
    const [error, setError] = useState('');
    const [players, setPlayers] = useState();
    const [playersNOMPast, setPlayersNOMPast] = useState();
    const [playersNOMFuture, setPlayersNOMFuture] = useState();
    const [activeMatch, setActiveMatch] = useState(null);
    const [changedPlayer, setChangedPlayer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    //const [readOnly, setReadOnly] = useState(false);
    const [showGroupMe, setShowGroupMe] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState("");


    // Error Handling
    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    // Get Permission Level
    /*
    function submitPassKeyHandler(keyPassVal) {
        let keyPassObj = {"readwrite": keyPassVal};
        getPermissionLevel(keyPassObj)
            .then((returnVal) => {
                if (returnVal.readwrite === constDefs.keyValuePass) {
                    setReadOnly(false) 
                } else {
                    setReadOnly(true)
                }
            }).catch(e => {
                let errMsg = "ERROR: getPermissionLevel: " + e;
                updateErrorHandler(errMsg);
            });
    }
    */

    function postToGroupMeHandler(id, groupMeText) {
        let matchObj = {
            "matchId": id,
            "message": groupMeText 
        };
        postToGroupMe(matchObj)
            .then((returnVal) => {
                alert("GroupMe Posted!");
            }).catch(error => {
                let errMsg = "ERROR: postToGroupMe: " + error;
                console.log(errMsg);
                updateErrorHandler(errMsg);
            });
    }

    function getPlayersForGenderAndLevel(players, gender, level) {
        return players.filter((player) => player.gender === gender && player.rating === level);
    }

    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    function getPlayerById(id) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return "";
        }
    }

    function getPlayerNameById(id) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer[0].name
        }
        else {
            return "";
        }
    }

    function setPlayerNOMs(matchesInfo, playersArray) {
        let playersMatchesPastArr = [];
        let playersMatchesFutureArr = [];
        let now = new Date((new Date().toString().split('GMT')[0]+' UTC'));
        let currentDate = now.toISOString().slice(0, -5);

        playersArray.forEach((player) => {
            var keyId = player.id;
            let numberOfMatchesPast = 0;
            let numberOfMatchesFuture = 0;
            matchesInfo.forEach((matchInfo) => {
                Object.entries(matchInfo.match_assignments).forEach((courtPlayer) => {
                    var val = courtPlayer[1];
                    if (keyId === val) {
                        if (matchInfo.match_datetime > currentDate) {
                            numberOfMatchesFuture++;
                        } else {
                            numberOfMatchesPast++;
                        }
                    }
                })
            });
            playersMatchesPastArr.push({'id': keyId, 'numOfMatches': numberOfMatchesPast});
            playersMatchesFutureArr.push({'id': keyId, 'numOfMatches': numberOfMatchesFuture});
        });
        setPlayersNOMPast(playersMatchesPastArr);
        setPlayersNOMFuture(playersMatchesFutureArr);
    }

    function setPlayerNOMChangedHandler(matchInfo, playerId, action) {
        let now = new Date((new Date().toString().split('GMT')[0]+' UTC'));
        let currentDate = now.toISOString().slice(0, -5);
        if (matchInfo.match_datetime > currentDate) {
            const newArray = playersNOMFuture.map((playerNum, i) => {
                if (playerNum.id === playerId) {
                    if (action === 'add') {
                        return {'id': playerId, 'numOfMatches': playerNum.numOfMatches + 1}
                    }
                    if (action === 'remove') {
                        return {'id': playerId, 'numOfMatches': playerNum.numOfMatches > 0 ? playerNum.numOfMatches - 1 : 0}
                    }
                } 
                return playerNum;
            });
            setPlayersNOMFuture(newArray);
        } else {
            const newArray = playersNOMPast.map((playerNum) => {
                if (playerNum.id === playerId) {
                    if (action === 'add') {
                        return {'id': playerId, 'numOfMatches': playerNum.numOfMatches + 1}
                    }
                    if (action === 'remove') {
                        return {'id': playerId, 'numOfMatches': playerNum.numOfMatches > 0 ? playerNum.numOfMatches - 1 : 0}
                    }
                }
                return playerNum;
            });
            setPlayersNOMPast(newArray);
        };
    }

    function setChangedPlayerHandler(playerId) {
        var chPlayer = "";
        if (activeMatch) {
            chPlayer = activeMatch[0].id.concat(",", playerId);
        }
        else {
            chPlayer = "noActiveMatch".concat(",", playerId);
        }
        setChangedPlayer(chPlayer);
    }

    function setMatchActiveHandler(match) {
        setActiveMatch(match);
    }

    function onToggleShowGroupMe(e) {
        setShowGroupMe(!showGroupMe);
    }

    function handleTextAreaChange(e) {
        setTextAreaValue(e.target.value);
    }

    function submitToGroupMe(e) {
        e.preventDefault();
        postToGroupMeHandler("", textAreaValue);
        setTextAreaValue("");
        setShowGroupMe(false);
    }

    // useEffect to retrieve Players 
    useEffect(() => {
            setIsLoading(true);
            getPlayers()
                .then((players) => {
                    setPlayers(players) 
                    let userId = sessionStorage.getItem(constDefs.authIdKey);
                    let foundPlayer = getPlayerByIdSecurity(userId, players);
                    if (foundPlayer.length > 0 && foundPlayer[0].hasOwnProperty('captain')) {
                        setReadOnly(false);
                        //setReadOnly(true);
                    } 

                    getMatches()
                        .then((matchesInfo) => {
                            setPlayerNOMs(matchesInfo, players) 
                        }).catch(error => {
                            let errMsg = "ERROR: getMatches: " + error;
                            console.log(errMsg);
                            updateErrorHandler(errMsg);
                        });
                }).catch(error => {
                    let errMsg = "ERROR: getPlayers: " + error;
                    updateErrorHandler(errMsg);
                });
            setIsLoading(false);
    }, []);


    function drag(ev) {
        setChangedPlayerHandler(ev.target.id);
    }

    /*
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.log('Running on locahost.');
    } else {
        console.log('Not running on localhost.');
    }
    */

    // Start building page content
    let womenTwoFive = [];
    let womenThreeZero = [];
    let womenThreeFive = [];
    let menTwoFive = [];
    let menThree = [];
    let menThreeFive = []

    if (players) {
       womenTwoFive = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.twoFive);
       womenThreeZero = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.threeZero);
       womenThreeFive = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.threeFive);
       menTwoFive = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.twoFive);
       menThree = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.threeZero);
       menThreeFive = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.threeFive);
    }

    // PlayerList content
    let playerListContent = "";

    if (readOnly) {
    } else {
        playerListContent = (
            <div className={classes.playerColumns}>
                <div className={classes.ratingGroupColumnBegin}>
                    <div className={classes.levelHeading}>2.5 Women</div>
                    <div className={classes.levelPlayerBlock}> 
                        {womenTwoFive.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player} playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ratingGroupColumn}>
                    <div className={classes.levelHeading}>3.5 Men</div>
                    <div className={classes.levelPlayerBlock}> 
                        {menThreeFive.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player} playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ratingColumn}>
                    <div className={classes.levelHeading}>3.0 Women</div>
                    <div className={classes.levelPlayerBlock}> 
                        {womenThreeZero.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player}  playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ratingGroupColumn}>
                    <div className={classes.levelHeading}>3.0 Men</div>
                    <div className={classes.levelPlayerBlock}> 
                        {menThree.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player} playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ratingColumn}>
                    <div className={classes.levelHeading}>3.5 Women</div>
                    <div className={classes.levelPlayerBlock}> 
                        {womenThreeFive.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player} playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ratingGroupColumn}>
                    <div className={classes.levelHeading}>2.5 Men</div>
                    <div className={classes.levelPlayerBlock}> 
                        {menTwoFive.map((player) => (
                            <div key={player.id}>
                                <PlayerList playerInfo={player} playersMatchesPast={playersNOMPast} playersMatchesFuture={playersNOMFuture} activeMatch={activeMatch} drag={drag} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        )
    }

    // Create GroupMe content
    let groupMeContent = "";
    let matchInfo = {id: ""};
    if (showGroupMe) {
        groupMeContent = (
            <div>
                <ModalGroupMe 
                    showModal={showGroupMe} 
                    setShowModal={onToggleShowGroupMe} 
                    submitToGroupMe={submitToGroupMe} 
                    matchInfo={matchInfo}
                    textAreaValue={textAreaValue} 
                    handleTextAreaChange={handleTextAreaChange} />
            </div>
        )
    }

    // Matches content
    let matchesContent = "";

    if (readOnly) {
    } else {
        matchesContent = (
            <LineupMatches 
                activeMatch={activeMatch} 
                changedPlayer={changedPlayer} 
                getPlayerNameById={getPlayerNameById}
                getPlayerById={getPlayerById}
                onSetMatchActive={setMatchActiveHandler} 
                onSetChangedPlayer={setChangedPlayerHandler}
                onSetPlayerNOMChange={setPlayerNOMChangedHandler}
                readOnly={readOnly}
                onError={updateErrorHandler}
            />
        )
    }

    let errorContent = "";
    if (error) {
        errorContent = (
            <div>
               <ErrorMsgHandling errorMessage={error} />
            </div>
        )
    }

    // Begin content setup
    let content = <p>No players found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up content for rendering
    if (players) {
        content = (
            <div>
                <div className={classes.componentSection}>
                    <div className={classes.leftSidePlayersLabel}>
                        <div>P</div>
                        <div>L</div>
                        <div>A</div>
                        <div>Y</div>
                        <div>E</div>
                        <div>R</div>
                        <div>S</div>
                    </div>
                    {playerListContent}
                    <div className={classes.buttonGroupMe} data-matchid="" onClick={onToggleShowGroupMe}>
                        Send text to GroupMe 
                    </div>
                </div>
                <div>
                    {groupMeContent}
                </div>
                <div className={classes.componentSection}> 
                    <div className={classes.leftSideMatchesLabel}>
                        <div>M</div>
                        <div>A</div>
                        <div>T</div>
                        <div>C</div>
                        <div>H</div>
                        <div>E</div>
                        <div>S</div>
                    </div>
                    {matchesContent}
                </div>
            </div>
        );
    }

    let lineupContent = "";
    if (readOnly) {
    } else {
        lineupContent = (
            <div>
                <div className={classes.lineupLegend}>
                    <div>( ) Denotes matches already played</div>
                    <div>  * Denotes future matches</div>
                </div>
                <div className={classes.lineupLayout}>  
                    {content}
                </div>
            </div>
        )
    }

    return (
        <div>
            {errorContent}
            {lineupContent}
        </div>

    ); 
}

export default Lineup;