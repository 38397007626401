/****************************************************
 * constants.js
 ***************************************************/

//Environment Variables
export const envLocalTest = "localTest";
export const envTest = "test";
export const envProd = "prod";

/*****   Select Your Environment Here  ***********/
//export const env = envLocalTest;
export const env = envTest;
//export const env = envProd;

// Urls
//export const getPlayersUrl = 'http://localhost:999/getplayers';
//export const getPlayersUrl = 'http://localhost:999/getplayerss';
export const sendPhoneNumberUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/process-otp';
export const senOTPUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/verify-otp';
export const verifyTokenUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/verify-token';
export const getPermissionLevelUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/get-readwrite-auth';
export const getPlayersUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/get-players';
//export const getMatchesUrl = 'http://localhost:999/getmatches';
//export const getMatchesUrl = 'http://localhost:999/getmatchess';
export const getMatchesUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/get-matches';
//export const postPlayersUrl = 'http://localhost:999/updateplayers';
export const postPlayersUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/update-players';
//export const postMatchesUrl = 'http://localhost:999/updatematches';
export const postMatchesUrl = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/update-matches';
export const postGroupMeMsg = 'https://ci1wjnqop5.execute-api.us-east-1.amazonaws.com/v1/post-groupme-message';


//Authorization Level
export const authKey = "readwrite";
export const keyValuePass = "yes";

//Authentication
export const tokenKey = "ttToken";
export const tokenExpireDays = 365;
export const authIdKey = "ttId";
export const authNameKey = "ttName";
export const authTokenValueTest = "abd1e2e4-bc0c-4649-a49d-4f14fb29f642";
//export const authTokenValueTest = "333333";

// Default Match Time
export const defaultMatchTime = "14:00";

// Genders
export const male = "male";
export const female = "female";

// Ratings
export const twoFive = "2.5";
export const threeZero = "3.0";
export const threeFive = "3.5";
export const maxRating = 6;

// Availability Options
export const availMystery = "mystery";
export const availYes = "yes";
export const availNo = "no";

// Match definitions
const tennisMatch1 = {
    "id": "1",
    "date": "04/07/2024",
    "dayOfWeek": "Sunday",
    "time": "2:00",
    "location": "Will Rogers Park",
    "opponent": "10 Aces",
};
const tennisMatch2 = {
    "id": "2",
    "date": "04/14/2024",
    "dayOfWeek": "Sunday",
    "time": "2:00",
    "location": "Earlywine",
    "opponent": "The Academy",
};
const tennisMatch3 = {
    "id": "3",
    "date": "04/26/2024",
    "dayOfWeek": "Friday",
    "time": "7:00",
    "location": "Will Rogers Park",
    "opponent": "Deucebags",
};
const tennisMatch4 = {
    "id": "4",
    "date": "04/28/2024",
    "dayOfWeek": "Sunday",
    "time": "2:00",
    "location": "Will Rogers Park",
    "opponent": "Hit Or Mixed",
};
const tennisMatch5 = {
    "id": "5",
    "date": "05/03/2024",
    "dayOfWeek": "Friday",
    "time": "7:00",
    "location": "Will Rogers Park",
    "opponent": "Tennis Team",
};

// Tennis Match Array
export const tennisMatchArray = [tennisMatch1, tennisMatch2, tennisMatch3, tennisMatch4, tennisMatch5];

//Players
const player1 = {
    "id": "1",
    "name": "Mac Lazarus",
    "gender": male,
    "rating": threeFive 
}
const player2 = {
    "id": "2",
    "name": "Kirk Purnell",
    "gender": male,
    "rating": threeFive 
}
const player3 = {
    "id": "3",
    "name": "John Joyce",
    "gender": male,
    "rating": threeZero 
}
const player4 = {
    "id": "4",
    "name": "Nes Pinaroc",
    "gender": male,
    "rating": threeZero 
}
const player5 = {
    "id": "5",
    "name": "Shannon Purnell",
    "gender": female,
    "rating": twoFive 
}
const player6 = {
    "id": "6",
    "name": "Theresa Tennis",
    "gender": female,
    "rating": twoFive 
}
const player7 = {
    "id": "7",
    "name": "Amy Lazarus",
    "gender": female,
    "rating": threeZero 
}
const player8 = {
    "id": "8",
    "name": "Elba Sisco",
    "gender": female,
    "rating": threeZero 
}

// Players Arrays
export const PlayersMenArray = [player1, player2, player3, player4];
export const PlayersWomenArray = [player5, player6, player7, player8];
export const PlayersArray = [...PlayersMenArray, ...PlayersWomenArray];
