import { useState, useEffect } from 'react';
import { getPlayers, getMatches, updateMatches, updatePlayers } from '../dataAccess/getData.js';
import { getMatchesSorted } from '../shared.js'

import MatchListAdmin from '../components/MatchListAdmin.js';
import MatchAdd from '../components/MatchAdd.js';
import MatchEdit from '../components/MatchEdit.js';
//import Pass from '../components/Pass.js'; 
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling.js'; 
import * as constDefs from '../constants/constants.js';
import classes from './AdminMatches.module.css';

//Component
function AdminMatches(props) {
    const [error, setError] = useState('');
    const [currentPlayers, setCurrentPlayers] = useState();
    const [currentMatches, setCurrentMatches] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [activeMatch, setActiveMatch] = useState(null);
    //const [readOnly, setReadOnly] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    // Error Handling
    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    // Get Permission Level
    /*
    function submitPassKeyHandler(keyPassVal) {
        let keyPassObj = {"readwrite": keyPassVal};
        getPermissionLevel(keyPassObj)
            .then((returnVal) => {
                if (returnVal.readwrite === constDefs.keyValuePass) {
                    setReadOnly(false) 
                } else {
                    setReadOnly(true)
                }
            }).catch(e => {
                let errMsg = "ERROR: getPermissionLevel: " + e;
                updateErrorHandler(errMsg);
            });
    }
    */

    function getMatchById(id) {
        let foundMatch = currentMatches.filter((match) => match.id === id);
        if (foundMatch.length > 0) {
            return foundMatch;
        }
        else {
            return "";
        }
    }

    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    function getDayOfWeek(dateStr) {
        const dt = new Date(dateStr);
        const day = dt.getDay();
        const dayNames = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        return dayNames[day];
    }

    function setMatchActiveHandler(event) {
        let matchId = event.target.getAttribute("data-matchid");
        setActiveMatch(matchId);
    }

    function getNewMatch(newMatches) {
        let newMatch = newMatches.filter(function(matchObj1) {
            return !currentMatches.some(function(matchObj2) {
                return matchObj1.id === matchObj2.id;
            })
        } )
        return newMatch;
    }

    function addNewMatchToPlayers(matchId) {
        const newMatchObj = {
            "matchId": matchId.toString(),
            "availability": ""
        } 
        const updatedPlayers = currentPlayers.map(player => {
            player.matches.push(newMatchObj);
            return player;
        });

        return updatedPlayers;
    }

    function addMatchHandler(selectedMatchDate, selectedMatchTime, selectedMatchOpponent, selectedMatchLocation) {
        let matchDateStr = selectedMatchDate ? selectedMatchDate : "2025-01-01";
        let timeStr = selectedMatchTime ? selectedMatchTime + ":00" : "";
        let dateTimeStr = matchDateStr + "T" + timeStr;
        let dayOfWeekStr = getDayOfWeek(dateTimeStr);
        let chMatchArr = [
            {
                id: "", 
                date: matchDateStr, 
                time: timeStr, 
                match_assignments: {'court1player1': '','court1player2': '','court2player1': '','court2player2': '','court3player1': '','court3player2': ''}, 
                opponent: selectedMatchOpponent, 
                location: selectedMatchLocation, 
                dayOfWeek: dayOfWeekStr, 
                match_datetime: dateTimeStr
            }];

        // POST updateMatches 
        try {
            updateMatches(chMatchArr, props.onError)
                .then(data => {
                    let result = data;
                    console.log(data);
                        setActiveMatch("");
                        setIsLoading(true);
                        getMatches()
                            .then((matches) => {
                                let newMatch = getNewMatch(matches);
                                if (newMatch && newMatch.length > 0 && newMatch[0].id) {
                                    let updatedPlayers = addNewMatchToPlayers(newMatch[0].id);
                                    if (updatedPlayers && updatedPlayers.length > 0) {
                                        // POST updatePlayers 
                                        try {
                                            updatePlayers(updatedPlayers)
                                                .then(data => {
                                                    setCurrentPlayers(updatedPlayers); 
                                                })
                                                .catch(error => {
                                                    let errMsg = "ERROR: catching updatePlayers: " + error;
                                                    updateErrorHandler(errMsg);
                                                })
                                        }
                                        catch(error) {
                                            let errMsg = "ERROR: updatePlayers: " + error;
                                            updateErrorHandler(errMsg);
                                        }
                                    }
                                }
                                setCurrentMatches(matches) 
                            }).catch(error => {
                                let errMsg = "ERROR: getMatches: " + error;
                                updateErrorHandler(errMsg);
                            });
                        setIsLoading(false);
                }).catch(error => {
                    let errMsg = "ERROR: catching updateMatches: " + error;
                    updateErrorHandler(errMsg);
                });
        }
        catch(error) {
            let errMsg = "ERROR: catching on try updateMatches: " + error;
            updateErrorHandler(errMsg);
        }
    }
        

    function updateMatchHandler(matchId, selectedMatchDate, selectedMatchTime, selectedMatchOpponent, selectedMatchLocation) {
        let chgMatch = getMatchById(matchId);
        if (chgMatch && chgMatch.length > 0 ) {
                let matchDateStr = selectedMatchDate ? selectedMatchDate : "2025-01-01";
                let timeStr = selectedMatchTime ? selectedMatchTime : "";
                let dateTimeStr = matchDateStr + "T" + timeStr;
                let dayOfWeekStr = getDayOfWeek(dateTimeStr);

                chgMatch[0].date = matchDateStr;
                chgMatch[0].time = timeStr;
                chgMatch[0].opponent = selectedMatchOpponent;
                chgMatch[0].location = selectedMatchLocation;
                chgMatch[0].dayOfWeek = dayOfWeekStr;
                chgMatch[0].match_datetime = dateTimeStr;
        
                // POST 
                try {
                    updateMatches(chgMatch, props.onError)
                        .then(data => {
                            let result = data;
                            console.log(data);
                                setActiveMatch("");
                                setIsLoading(true);
                                getMatches()
                                    .then((matches) => {
                                        setCurrentMatches(matches) 
                                    }).catch(error => {
                                        let errMsg = "ERROR: getMatches: " + error;
                                        updateErrorHandler(errMsg);
                                    });
                                setIsLoading(false);
                        }).catch(error => {
                            let errMsg = "ERROR: catching updateMatches: " + error;
                            updateErrorHandler(errMsg);
                        });
                }
                catch(error) {
                    let errMsg = "ERROR: catching on try updateMatches: " + error;
                    updateErrorHandler(errMsg);
                }         
        }
    }

    function handleClearFields() {
        setActiveMatch('');
    }

    // useEffect to retrieve Matches 
    useEffect(() => {
            setIsLoading(true);
            getMatches(props.onError)
                .then((matchesContent) => {
                    setCurrentMatches(matchesContent) 
                }).catch(error => {
                    let errMsg = "ERROR: getMatches: " + error;
                    console.log(errMsg);
                    props.onError(errMsg);
                });

            setIsLoading(false);
    }, []);

    // useEffect to retrieve Players 
    useEffect(() => {
            setIsLoading(true);
            getPlayers()
                .then((players) => { 
                    let userId = sessionStorage.getItem(constDefs.authIdKey);
                    let foundPlayer = getPlayerByIdSecurity(userId, players);
                    if (foundPlayer.length > 0 && foundPlayer[0].hasOwnProperty('captain')) {
                        setReadOnly(false);
                        //setReadOnly(true);
                    } 
                    setCurrentPlayers(players);
                }).catch(error => {
                    let errMsg = "ERROR: getPlayers: " + error;
                    updateErrorHandler(errMsg);
                });
            setIsLoading(false);
    }, []);

    // Start building page content
    let sortedMatches = [];

    if (currentMatches) {
       sortedMatches = getMatchesSorted(currentMatches);
    }

    let matchListContent = "";
    let addEditMatchContent = "";
    let addEditMatchLabel = "";
    let clearFieldsButton = "";

    if (readOnly) {
    } else {
        matchListContent = (
            <div className={classes.matchColumns}>
                {sortedMatches.map((match) => (
                    <div key={match.id}>
                        <MatchListAdmin matchInfo={match} activeMatch={activeMatch} onSetMatchActive={setMatchActiveHandler}  />
                    </div>
                ))}
            </div>
        )
        if (activeMatch) {
            addEditMatchLabel = 'Edit Match';
            addEditMatchContent = (
                <div className={classes.addEditMatchInput} >
                    <MatchEdit activeMatch={activeMatch} getMatchById={getMatchById} onUpdateMatch={updateMatchHandler } />
                </div>
            )
            clearFieldsButton = (
                <button className={classes.clearFieldsButton} onClick={handleClearFields}>
                    Clear Fields 
                </button>
            )
        } else 
        {
            addEditMatchLabel = 'Add Match';
            addEditMatchContent = (
                <div className={classes.addEditMatchInput} >
                    <MatchAdd onAddMatch={addMatchHandler } />
                </div>
            )
        }
    }

    let errorContent = "";
    if (error) {
        errorContent = (
            <div>
               <ErrorMsgHandling errorMessage={error} />
            </div>
        )
    }

    // Begin content setup
    let content = <p>No matches found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up MatchesListContent
    if (readOnly) {
    } else {
        if (currentMatches) {
            content = (
                <div>
                    <div className={classes.componentSection}>
                        <div className={classes.matchesLabel}>
                            Matches
                        </div>
                        {matchListContent}

                        <div className={classes.addEditMatchSection}>
                            <div className={classes.addEditLabelSection}>
                                <div className={classes.addEditMatchesLabel}>
                                    {addEditMatchLabel}
                                </div>
                                {clearFieldsButton}
                            </div>
                            {addEditMatchContent}
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={classes.adminMatchesLayout}>
            {errorContent}
            {content}
        </div>
    ); 
}

export default AdminMatches;