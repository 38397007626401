import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { getPlayers } from '../../dataAccess/getData.js';
import * as constDefs from '../../constants/constants'; 
import classes from './MainNavigation.module.css';

function MainNavigation() {
    const [currentPlayer, setCurrentPlayer] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    let userName = sessionStorage.getItem(constDefs.authNameKey);


    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    // useEffect to retrieve Players 
    useEffect(() => {
            setIsLoading(true);
            getPlayers()
                .then((players) => {
                    let userId = sessionStorage.getItem(constDefs.authIdKey);
                    let foundPlayer = getPlayerByIdSecurity(userId, players);
                    setCurrentPlayer(foundPlayer);
                    if (foundPlayer.length > 0 && foundPlayer[0].hasOwnProperty('captain')) {
                        setReadOnly(false);
                        //setReadOnly(true);
                    } 
                }).catch(error => {
                    let errMsg = "ERROR: getPlayers: " + error;
                    console.log(errMsg);
                    //updateErrorHandler(errMsg);
                });
            setIsLoading(false);
    }, []);


    // Begin content setup
    let content = <p>No players found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up PlayersListContent
    if (readOnly) {
        content = (
            <nav>
                <ul className={classes.links}>
                    <li className={classes.link}>
                        <NavLink to="/availabilityupdate" className={({ isActive}) => isActive ? classes.active : undefined } end
                        >
                            Availability Update
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/availability" className={({ isActive}) => isActive ? classes.active : undefined } end
                        >
                            Availability
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/lineup" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Lineup 
                        </NavLink>
                    </li>
                </ul>
            </nav>
        )
    } else {
        content = (
            <nav>
                <ul className={classes.links}>
                    <li className={classes.link}>
                        <NavLink to="/availabilityupdate" className={({ isActive}) => isActive ? classes.active : undefined } end
                        >
                            Availability Update
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/availability" className={({ isActive}) => isActive ? classes.active : undefined } end
                        >
                            Availability
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/lineup" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Lineup 
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/lineupadmin" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Admin Lineup 
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/adminAvailability" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Admin Availability 
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/adminPlayers" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Admin Players 
                        </NavLink>
                    </li>
                    <li className={classes.link}>
                        <NavLink to="/adminMatches" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Admin Matches 
                        </NavLink>
                    </li>
                </ul>
            </nav>
        )
    }


    return(
        <div className={classes.header}>
            <div className={classes.headerName}>Hello, {userName}</div>
            {content}
        </div>
    );
}

export default MainNavigation;