import * as constDefs from '../constants/constants';
import classes from './PlayerListAdmin.module.css';

function PlayerListAdmin(props) {

    let playerListRowClass = classes.playerListRow;
    let playerNameClass = classes.playerNameButton;

    if (props.activePlayer && props.activePlayer === props.playerInfo.id) {
        playerListRowClass = `${classes.playerListRow} ${classes.playerActive}`; 
        playerNameClass = `${classes.playerNameButton} ${classes.playerNameActive}`;
    }
 
    return (
        <div className={`${playerListRowClass}`} id={props.playerInfo.id}  >
            <button className={`${playerNameClass}`} id={props.playerInfo.id} onClick={props.onSetPlayerActive}>
                {props.playerInfo.name}
            </button>
        </div>
    ); 
}

export default PlayerListAdmin;