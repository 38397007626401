import { useState } from 'react'; 
import * as constDefs from '../constants/constants';
import classes from './MatchAdd.module.css';

function MatchAdd(props) {
    const [selectedMatchDate, setSelectedMatchDate] = useState('');
    const [selectedMatchTime, setSelectedMatchTime] = useState(constDefs.defaultMatchTime);
    const [selectedMatchOpponent, setSelectedMatchOpponent] = useState('');
    const [selectedMatchLocation, setSelectedMatchLocation] = useState('');

    const handleChangeDate = (event) => {
        setSelectedMatchDate(event.target.value);
    }

    const handleChangeTime = (event) => {
        setSelectedMatchTime(event.target.value);
    }

    const handleChangeOpponent = (event) => {
        setSelectedMatchOpponent(event.target.value);
    }

    const handleChangeLocation = (event) => {
        setSelectedMatchLocation(event.target.value);
    }

    function handleAdd(event) {
        event.preventDefault();
        console.log(selectedMatchDate);
        console.log(selectedMatchTime);
        console.log(selectedMatchOpponent);
        console.log(selectedMatchLocation);
        props.onAddMatch(selectedMatchDate, selectedMatchTime, selectedMatchOpponent, selectedMatchLocation);
    }

    return (
        <div className={classes.matchAddEdit}  >
            <form className={classes.matchAddEditForm}  >
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchDate">Date:</label>
                    <input className={classes.inputBox} type="date" id="matchDate" name="matchDate" value={selectedMatchDate} onChange={handleChangeDate} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchTime">Time:</label>
                    <input className={classes.inputBox} type="time" id="matchTime" name="matchTime" value={selectedMatchTime} onChange={handleChangeTime} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchOpponent">Opponent:</label>
                    <input className={classes.inputBoxName} type="text" id="matchOpponent" name="matchOpponent" value={selectedMatchOpponent} onChange={handleChangeOpponent} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchLocation">Location:</label>
                    <input className={classes.inputBoxName} type="text" id="matchLocation" name="matchLocation" value={selectedMatchLocation} onChange={handleChangeLocation} />
                </div>
                <button className={classes.matchAddButton} type="submit" onClick={handleAdd}>Add</button>
            </form>
        </div>
    ); 
}

export default MatchAdd;