import { useState } from 'react'; 
import * as constDefs from '../constants/constants';
import classes from './MatchAdd.module.css';

function MatchEdit(props) {
    const [activeMatch, setActiveMatch] = useState('');
    const [selectedMatchDate, setSelectedMatchDate] = useState('');
    const [selectedMatchTime, setSelectedMatchTime] = useState('');
    const [selectedMatchOpponent, setSelectedMatchOpponent] = useState('');
    const [selectedMatchLocation, setSelectedMatchLocation] = useState('');

    /*
    if (props.activeMatch && props.activeMatch === "") {
        setSelectedMatchDate("");
        setSelectedMatchTime("");
        setSelectedMatchOpponent("");
        setSelectedMatchLocation("")
    }
        */

    const handleChangeDate = (event) => {
        setSelectedMatchDate(event.target.value);
    }

    const handleChangeTime = (event) => {
        setSelectedMatchTime(event.target.value);
    }

    const handleChangeOpponent = (event) => {
        setSelectedMatchOpponent(event.target.value);
    }

    const handleChangeLocation = (event) => {
        setSelectedMatchLocation(event.target.value);
    }

    if (props.activeMatch && props.activeMatch !== activeMatch) {
        let match = props.getMatchById(props.activeMatch);
        setSelectedMatchDate(match[0].date ? match[0].date : "");
        setSelectedMatchTime(match[0].time ? match[0].time : "");
        setSelectedMatchOpponent(match[0].opponent ? match[0].opponent : "");
        setSelectedMatchLocation(match[0].location ? match[0].location : "");
        setActiveMatch(match[0].id);
    }

    function handleUpdate(event) {
        event.preventDefault();
        console.log(activeMatch);
        console.log(selectedMatchDate);
        console.log(selectedMatchTime);
        console.log(selectedMatchOpponent);
        console.log(selectedMatchLocation);
        props.onUpdateMatch(activeMatch, selectedMatchDate, selectedMatchTime, selectedMatchOpponent, selectedMatchLocation);
    }

    return (
        <div className={classes.matchAddEdit}  >
            <form className={classes.matchAddEditForm}  >
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchDate">Date:</label>
                    <input className={classes.inputBox} type="date" id="matchDate" name="matchDate" value={selectedMatchDate} onChange={handleChangeDate} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchTime">Time:</label>
                    <input className={classes.inputBox} type="time" id="matchTime" name="matchTime" value={selectedMatchTime} onChange={handleChangeTime} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchOpponent">Opponent:</label>
                    <input className={classes.inputBoxName} type="text" id="matchOpponent" name="matchOpponent" value={selectedMatchOpponent} onChange={handleChangeOpponent} />
                </div>
                <div className={classes.inputDivSection}>
                    <label className={classes.inputLabel} htmlFor="matchLocation">Location:</label>
                    <input className={classes.inputBoxName} id="matchLocation" name="matchLocation" value={selectedMatchLocation} onChange={handleChangeLocation} />
                </div>

                <button className={classes.matchAddButton} type="submit" onClick={handleUpdate}>Update</button>
            </form>
        </div>
    ); 
}

export default MatchEdit;