import { Outlet } from 'react-router-dom';
import classes from './Layout.module.css';

//import MainNavigation from "./MainNavigation";

function Layout() {

    //console.log("In Layout");

    return (
        <div id='layout'>
            <main className={classes.main}>
                <Outlet />
            </main>
        </div>
    );
}

export default Layout;