import MatchInfo from './MatchInfo';
import MatchInfoAvailability from './MatchInfoAvailability';
import {updatePlayers } from '../dataAccess/getData';
import classes from './MatchesUpdateAvailability.module.css';

function Matches( props) {

    function updateAvailabilityHandler(e) {
        let playerId = e.target.getAttribute("data-playerid");
        let matchId = e.target.getAttribute("data-matchid");
        let availability = e.target.getAttribute("data-available");

        const updatedPlayer = props.loadedPlayerAvailability.map(player => {
            if (player.id === playerId) {
                let updatedMatches = player.matches.map(match => {
                    if (match.matchId === matchId) {
                        return {
                            ...match,
                            availability: match.availability = availability
                        };
                    }
                    else {
                        return match;
                    }
                })
                return {
                    ...player,
                    matches: updatedMatches
                }
            } else {
               return player;
            }
        });

        // POST 
        try {
            updatePlayers(updatedPlayer)
                .then(data => {
                    props.onUpdatePlayerHandler(updatedPlayer);
                })
                .catch(error => {
                    let errMsg = "ERROR: catching updatePlayers: " + error;
                    props.onError(errMsg);
                })
        }
        catch(error) {
            props.onError(error);
        }
     }


    return (
        <div className={classes.matchesLayout}>
            <div className={classes.matchInfoBlock}>
                <div className={classes.titleMatchInfoPlayers}>Player</div>               
                <div className={classes.titleMatchInfoPlayer}>{props.currentPlayer.name}</div>
            </div>
            <div className={classes.matchesAvailBlock}>
                {props.matchList.map((match) => (
                    <div className={classes.matchesDetail} key={match.id}>
                        <div className={classes.matchBlock}>
                        <MatchInfo matchInfo={{match}} />
                        </div>
                    <MatchInfoAvailability matchInfo={match} readOnly={props.readOnly} player={props.currentPlayer} onUpdateAvailability={updateAvailabilityHandler} />
                </div>
                ))}
            </div>
        </div>
    ); 
}

export default Matches;