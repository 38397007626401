import { useEffect } from 'react'; 
import { Outlet, useLocation  } from "react-router-dom";

import MainHeader from "../components/shared/MainHeader";
import MainNavigation from "../components/shared/MainNavigation";
import Footer from "../components/shared/Footer";
import Login from "../pages/Login";
import useToken from '../useToken';
import { verifyToken } from '../dataAccess/getData'; 
import * as constDefs from '../constants/constants';

function Root() {
    //console.log("In Root.js");
    const currentLocation = useLocation();
    //console.log("currentLocation is: " + currentLocation.pathname);

    const { token, setToken } = useToken(); 

    let uname = sessionStorage.getItem(constDefs.authNameKey);
    //console.log("In Root userName is: " + uname);

    // useEffect to verify Token 
    useEffect(() => {
        //console.log("In Root, in useEffect.");
        if (!sessionStorage.getItem(constDefs.authIdKey)) {
            let tokenObj = {"token": token};
            verifyToken(tokenObj)
                .then((returnVal) => {
                        if (returnVal.tokenExists === 'yes') {
                            if (returnVal.id) {
                                sessionStorage.setItem(constDefs.authIdKey, returnVal.id);
                                sessionStorage.setItem(constDefs.authNameKey, returnVal.name);
                                //console.log("In Root, setting sessionStorage.");
                            } else {
                                sessionStorage.removeItem(constDefs.authIdKey);
                                sessionStorage.removeItem(constDefs.authNameKey);
                                return <Login setToken={setToken} />
                            }
                        } else {
                            sessionStorage.removeItem(constDefs.authIdKey);
                            sessionStorage.removeItem(constDefs.authNameKey);
                            return <Login setToken={setToken} />
                        }
                    })
                .catch(e => {
                    let errMsg = "ERROR: verifyToken: " + e;
                });
        }
    }, []);


    if (!token) {
        return <Login setToken={setToken} />
    }

    //console.log("currentLocation at rootContent is: " + currentLocation.pathname);
    let rootContent = "";
    
    rootContent = (
        <>
            <header>
                <MainHeader />
            </header>
            <div>
                <MainNavigation />
            </div>
                <Outlet />
            <div>
                <Footer />
            </div>
        </>
    );

    return (
        <div>
            {rootContent}
        </div>
    ) 
}

export default Root;
