import PlayerCategories from './PlayerCategories';
import {updatePlayers } from '../dataAccess/getData';
import classes from './PlayersAvailability.module.css';

//Component
function PlayersAvailability(props) {

    function getPlayersAvailabilitySorted(players) {
        let sortedPlayers = players.sort((a,b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return sortedPlayers;
    };

    function updateAvailabilityHandler(e) {
        let playerId = e.target.getAttribute("data-playerid");
        let matchId = e.target.getAttribute("data-matchid");
        let availability = e.target.getAttribute("data-available");

        const updatedPlayers = props.loadedPlayersAvailability.map(player => {
            if (player.id === playerId) {
                let updatedMatches = player.matches.map(match => {
                    if (match.matchId === matchId) {
                        return {
                            ...match,
                            availability: match.availability = availability
                        };
                    }
                    else {
                        return match;
                    }
                })
                return {
                    ...player,
                    matches: updatedMatches
                }
            } else {
                return player;
            }
        });

        // POST 
        try {
            let returnVal = updatePlayers(updatedPlayers, props.onError);
            // Update state
            //setLoadedPlayersAvailability(updatedPlayers); 
            props.onUpdatePlayerHandler(updatedPlayers);
        }
        catch(error) {
            props.onError(error);
        }
    }

    // Begin content setup
    let content = <p>No players found.</p>;

    // Set up content for rendering
    if (props.loadedPlayersAvailability) {
       let playersSorted = getPlayersAvailabilitySorted(props.loadedPlayersAvailability);
        content = (
            <div className={classes.levelPlayerBlock}> 
                {playersSorted.map((player) => (
                    <div key={player.id}>
                        <PlayerCategories readOnly={props.readOnly} playerInfo={player} matchList={props.matchList} onUpdateAvailability={updateAvailabilityHandler}  />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={classes.playersLayout}> 
            {content}
        </div>
    ); 
}

export default PlayersAvailability;
