import PlayerInfo from './PlayerInfo';
import MatchAvailability from './MatchAvailability';
import classes from './PlayerCategories.module.css';
//import { compareMatchId } from '../shared';

function PlayerCategories(props) {

    let sortedMatches = [];

    props.matchList.forEach((match) => {
        if (props.playerInfo && props.playerInfo.matches) {
            props.playerInfo.matches.forEach((playerMatch) => {
                if (playerMatch.matchId === match.id ) {
                    sortedMatches.push(playerMatch);
                }
            })
        }
    });

    //sortedMatches = props.playerInfo.matches.sort((a,b) => (a.matchId > b.matchId) ? 1 : ((a.matchId < b.matchId) ? -1 : 0));
    //sortedMatches = props.playerInfo.matches.sort(compareMatchId);
 
    return (
        <div className={classes.playerColumn} >
            <PlayerInfo className={classes.playerNameBlock} playerInfo={props.playerInfo.name} />
            {sortedMatches.map((matchAvail) => (
                <div className={classes.playerButtonBlock} key={matchAvail.matchId}>
                    <MatchAvailability 
                        readOnly={props.readOnly}
                        playerId={props.playerInfo.id} 
                        matchAvailInfo={matchAvail} 
                        onUpdateAvailability={props.onUpdateAvailability} 
                    /> 
                </div>
            ))}
        </div>
    ); 
}

export default PlayerCategories;
