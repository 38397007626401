//import MatchInfo from '../components/MatchInfo';
import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { getMatches, getPlayers, getPlayersAvailability } from '../dataAccess/getData';
//import {getPermissionLevel } from '../dataAccess/getData';

import MatchesUpdateAvailability from '../components/MatchesUpdateAvailability';
import PlayerAvailabilityUpdate from '../components/PlayerAvailabilityUpdate';
import PlayersAvailability from '../components/PlayersAvailability';
import * as constDefs from '../constants/constants';
import classes from './AvailabilityUpdate.module.css';
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling'; 

function AvailabilityUpdate() {
    const [error, setError] = useState('');
    const [loadedPlayerAvailability, setLoadedPlayerAvailability] = useState();
    const [currentPlayer, setCurrentPlayer] = useState();
    const [readOnly, setReadOnly] = useState(true);
    const loadedMatches = useLoaderData();
    const [isLoading, setIsLoading] = useState(false);

    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    function getPlayerByIdSecurity(id, players) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return [];
        }
    }

    function updatePlayerHandler(player) {
        setCurrentPlayer(player);
        setLoadedPlayerAvailability(player); 
    }

    useEffect(() => {
        setIsLoading(true);
        getPlayersAvailability()
            .then((playersAvailability) => {
                let userId = sessionStorage.getItem(constDefs.authIdKey);
                let foundPlayer = getPlayerByIdSecurity(userId, playersAvailability);
                if (foundPlayer.length > 0) {
                    setCurrentPlayer(foundPlayer);
                    setLoadedPlayerAvailability(foundPlayer);  
                    setReadOnly(false);
                }
            }).catch(error => {
                let errMsg = "ERROR: getPlayersAvailability: " + error;
                console.log(errMsg);
                updateErrorHandler(errMsg);
            });

        setIsLoading(false);
    }, []);

    if (!Array.isArray(loadedMatches)) {
        if (loadedMatches && loadedMatches.startsWith("ERROR") ) {
            //Note: The following updateErrorHandler call produces an infinite loop. Don't use it like this.
            //      Instead, use the errorElement property provided with React Router.
            //updateErrorHandler(loadedMatches);
            throw new Error("Error from Availability Update component - " + loadedMatches);
        }
    }

    let errorContent = "";
    if (error) {
        errorContent = (
            <div>
               <ErrorMsgHandling errorMessage={error} />
            </div>
        )
    }

    let content = <p>Welcome to Tennis Time.</p>;
    //const currentPlayerPerm = false;

    if (isLoading) {
        content = <p>Welcome to Tennis Time.</p>;
    }

    if (loadedPlayerAvailability) {
        if (currentPlayer) {
            content = (
                <div>
                    <div className={classes.availabilityUpdateLayout}>
                        <ErrorMsgHandling errorMessage={error} />
                        <MatchesUpdateAvailability 
                            onError={updateErrorHandler} 
                            readOnly={readOnly} 
                            matchList={loadedMatches} 
                            loadedPlayerAvailability={loadedPlayerAvailability}
                            currentPlayer={currentPlayer[0]} 
                            onUpdatePlayerHandler={updatePlayerHandler} />
                    </div>
                </div>
                )
        }
    }

    return (
        <div>
            {errorContent}
            {content}
        </div>
    ); 
}

export default AvailabilityUpdate;

export function loader() {
    const response = getMatches()
        .then((matches) => {
            return matches;
        })
        .catch(error => {
            let errMsg = "ERROR: getMatches: " + error;
            console.log(errMsg);
            return errMsg; 
        });
    return response;
}